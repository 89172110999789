import React, { useState } from 'react';
import cx from 'classnames';
import dynamic from 'next/dynamic';

import styles from './index.module.scss';

const IconArrowCircleSlider = dynamic(() =>
  import('#app/UI/atoms/icons/arrowCircleSlider')
);
const Typography = dynamic(() => import('#app/UI/atoms/new-typography'));

const dataValueProposal = [
  {
    student: '🇵🇪 Benjamín García, Samsung Innovation Campus 2023',
    description:
      '“El programa de Crack The Code, no solo fue determinante para mi transición laboral, sino que se convirtió en un pilar fundamental para la obtención de mi posición actual. La estructura y enfoque del curso, fueron cruciales para destacar en el proceso de selección” ',
    img: '/home/students-testimonials/student_1.webp'
  },
  {
    student: '🇲🇽 Daniela, Educare Play',
    description:
      '“El proyecto con Crack The Code tendrá un impacto en mi futuro porque ahora entiendo mejor el mundo de la programación, lo que hará que sea más sencillo elegir una carrera, ya que conozco sobre el entorno”',
    img: '/home/students-testimonials/student_2.webp'
  },
  {
    student: '🇲🇽 Emilio, Educare Play',
    description:
      '“Lo que aprendí en Crack The Code, es muy útil para mi futuro. Además, quiero seguir capacitándome porque si me dedico a la tecnología y programación, será muy divertido e importante para mi”',
    img: '/home/students-testimonials/student_3.webp'
  },
  {
    student: '🇨🇴  Walter Artica, Samsung Innovation Campus 2023',
    description:
      '“Crack The Code además de capacitarnos en habilidades técnicas, se preocupa mucho por las habilidades blandas, se consideraron temas como: empatía, comunicación, trabajo en equipo y resolución de problemas, lo que me está ayudando a desarrollarme laboralmente de manera exitosa” ',
    img: '/home/students-testimonials/student_4.webp'
  }
];
const CardTestimonial = ({ student, description, img }) => {
  return (
    <div className={styles.card}>
      <img className={cx(styles.image, styles.desktop)} src={img} alt={student} />
      <div className={styles.text}>
        <Typography className={styles.cardDescription} tag="p">
          {description}
        </Typography>
        <div className={styles.containerStudent}>
          <img className={cx(styles.image, styles.mobile)} src={img} alt={student} />
          <Typography className={styles.cardStudentName} tag="p">
            {student}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const StudentsTestimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(dataValueProposal.length - 1);
    }
  };

  const nextSlide = () => {
    if (currentIndex < dataValueProposal.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  return (
    <section className={styles.container}>
      <Typography className={styles.title} tag="h2">
        Testimonios de <span className={styles.mainColor}>nuestros estudiantes</span>
      </Typography>
      <div className={styles.body}>
        <div className={styles.slider}>
          <div className={styles.containerCards}>
            {/* Arrow Left */}
            <div className={styles.arrowLeft} onClick={() => prevSlide()}>
              <IconArrowCircleSlider />
            </div>
            {/* Card */}
            <CardTestimonial {...dataValueProposal[currentIndex]} />
            {/* Arrow Right */}
            <div className={styles.arrowRight} onClick={() => nextSlide()}>
              <IconArrowCircleSlider />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.containerDots}>
        {dataValueProposal.map((value, idx) => (
          <div
            className={styles.dot}
            onClick={() => setCurrentIndex(idx)}
            key={`dot-testimonial-${idx}`}
            style={{ backgroundColor: idx === currentIndex ? '#FFAA7D' : '#B9BFBD' }}
          ></div>
        ))}
      </div>
    </section>
  );
};

export default StudentsTestimonials;
